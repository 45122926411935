<template>
    <h1>WebSocket 测试</h1>
    <el-row v-if="connected">
        <el-col :span="12" style="padding: 10px;">
            <div>
                <div style="display: flex; justify-content: space-between ;margin: 10px 40px;">
                    <div>电机: <el-tag :type="motorConnected ? 'success' : 'info'">{{ motorConnected ? '在线' : '离线'
                            }}</el-tag>
                    </div>
                    <div>仪表: <el-tag :type="dispalyConnected ? 'success' : 'info'">{{ dispalyConnected ? '在线' : '离线'
                            }}</el-tag>
                    </div>
                </div>
                <div style="display: flex;flex-wrap: wrap;">
                    <el-tooltip trigger="focus" style="margin: 5px;" v-for="param, cmd in cmds" :key="cmd"
                        :content="param.name" placement="top">
                        <el-button :disabled="!param.enable" style="margin: 5px;" @click="execCmd(cmd)">{{
        param.buttonName }}</el-button>
                    </el-tooltip>
                </div>

                <div style="margin-top:60px">
                    <el-input type="textarea" :rows="6" v-model="newMessage" />
                    <div style="text-align: right;margin-top: 20px;">
                        <el-button type="primary" @click="sendMessage">发送</el-button>
                    </div>

                </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div style="text-align: left; margin-bottom: 10px;">
                <el-button @click="clearMessage">清空</el-button>
            </div>
            <div style="margin-right: 5px;height: 550px;">
                <el-scrollbar style="border: 1px solid #e2e2e2;">
                <div style="text-align: left;padding:2px 8px; word-break: break-all;" v-for="message, key in messages" :key="key">
                    <el-icon>
                        <ArrowUp v-if="message.sender == 'me'" />
                        <ArrowDown v-else />
                    </el-icon>
                    <span>{{ message.time }}</span>: {{ message.content }}
                </div>
            </el-scrollbar>
            </div>
            
        </el-col>
    </el-row>

    <div v-else>Connecting to WebSocket...<el-button @click="initConnect">重连</el-button></div>
</template>

<script setup>

import { ref, onMounted, onUnmounted } from 'vue'
import { formatDateTime } from '@/utils/common.js'
import { ElMessage } from 'element-plus';


const connected = ref(false)
const newMessage = ref(null)
const motorConnected = ref(false)
const dispalyConnected = ref(false)


const messages = ref([])

let ws = null;

const cmds = ref({
    getExeVer: { name: '获取插件程序的版本号', buttonName: '插件版本', param: '', enable: true },
    getAppPath: { name: '获取插件程序的运行目录', buttonName: '插件目录', param: '', enable: true },
    getComPorts: { name: '获取本机的串口信息', buttonName: '串口信息', param: '', enable: true },
    getProtocal: { name: '获取通讯协议', buttonName: '通讯协议', param: '', enable: true },
    connectMotor: { name: '连接电机', buttonName: '连接电机', param: { timeout: '10000' }, enable: true },
    getMotorInfo: { name: '获取电机的基础信息(版本信息)', buttonName: '电机版本信息', param: '', enable: motorConnected },
    getMotorInfo_Config: { name: '获取电机的配置信息(电流等级，限速档位, 启用的传感器外设、里程数，车轮尺寸)', buttonName: '电机配置信息', param: '', enable: motorConnected },
    getMotorInfo_RealTime: { name: '获取电机实时分析信息', buttonName: '电机实时分析', param: '', enable: motorConnected },
    getMotorInfo_SelfError: { name: '获取电机自检错误', buttonName: '电机自检', param: '', enable: motorConnected },
    getMotorInfo_HisError: { name: '电机历史故障', buttonName: '电机历史故障', param: '', enable: motorConnected },
    startMotorTest: { name: '开启电机测试', buttonName: '开启电机测试', param: '', enable: motorConnected },
    stopMotorTest: { name: '关闭电机测试', buttonName: '关闭电机测试', param: '', enable: motorConnected },
    getBatteryInfo_Detail: { name: '电池信息', buttonName: '电池信息', param: '', enable: true },
    getBatteryInfo_Realtime: { name: '获取电池实时电流', buttonName: '电池电流', param: '', enable: true },
    connectDisplay: { name: '连接仪表', buttonName: '连接仪表', param: { timeout: '3000' }, enable: true },
    getDisplayInfo: { name: '仪表信息', buttonName: '仪表信息', param: '', enable: dispalyConnected },
    getDisplayInfo_Config: { name: '仪表配置信息', buttonName: '仪表配置信息', param: '', enable: dispalyConnected },
    getDisplayInfo_RealTimeDatas: { name: '仪表实时信息', buttonName: '仪表实时信息', param: '', enable: dispalyConnected },
    openLight: { name: '开灯', buttonName: '开灯', param: '', enable: dispalyConnected },
    closeLight: { name: '关灯', buttonName: '关灯', param: '', enable: dispalyConnected },

    getDisplayInfo_boot: { name: 'boot模式下获取仪表版本信息', buttonName: 'b仪表信息', param: '', enable: true },
    getMotorInfo_boot: { name: 'boot模式下获取电机版本信息', buttonName: 'b电机信息', param: '', enable: true },
    closePort: { name: '关闭端口', buttonName: '关闭端口', param: '', enable: true },
})

function sendMessage() {
    messages.value.push({
        sender: 'me',
        content: newMessage.value,
        time: formatDateTime()
    })
    ws.send(newMessage.value)
}

onMounted(() => {
    initConnect()

})
onUnmounted(() => {
    if (ws) {
        ws.close()
    }
})

function initConnect() {
    ws = new WebSocket('ws://127.0.0.1:1234')
    ws.onopen = () => {
        connected.value = true

    }
    ws.onclose = () => {
        connected.value = false
        motorConnected.value = false
        dispalyConnected.value = false
    }
    ws.onerror = () => {
    }
    ws.onmessage = message => {
        messages.value.push(
            {
                sender: 'ws',
                content: message.data,
                time: formatDateTime()
            }
        )
        let messageData = JSON.parse(message.data)
        if (messageData.cmd === 'connectMotor') {
            if (messageData.code == 200) motorConnected.value = true
        }

        if (messageData.cmd === 'connectDisplay') {
            if (messageData.code == 200) dispalyConnected.value = true
        }
        if (messageData.code == 0) {
            ElMessage.error(messageData.message || '执行命令失败')
            return
        }

    }
}

function execCmd(cmd) {
    let message = { cmd: cmd, param: '' }
    if (cmds.value[cmd].param) {
        message = {
            cmd: cmd,
            ...cmds.value[cmd].param
        }
    }
    let messageJson = JSON.stringify(message)

    messages.value.push({
        sender: 'me',
        content: messageJson,
        time: formatDateTime()
    })
    ws.send(messageJson)
}

function clearMessage() {
    messages.value = []
}



</script>