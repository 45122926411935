<template>
    <div style="margin: 20px; text-align: left;">
        <h3 style="text-align: left; margin-left:20px">
            插件管理
        </h3>
        <div style="text-align: right;">
            <el-button @click="showAdd" type="primary">添加</el-button>
        </div>
        <div style="margin-top: 20px;">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="id" width="80" label="编号" />
                <el-table-column prop="version" width="150" label="版本" />
                <el-table-column prop="status" width="80" label="状态">
                    <template #default="scope">
                        <el-switch :active-value=1 :inactive-value=0 :model-value="scope.row.status"
                            @change="changeStatus(scope.row)"></el-switch>


                    </template>
                </el-table-column>
                <el-table-column prop="file_name" label="文件" />
                <el-table-column prop="desc" label="描述" />
                <el-table-column label="操作" fixed="right" width="120">
                    <template #default="scope">
                        <el-popconfirm title="确认删除吗？" @confirm="itemDelete(scope.row)">
                            <template #reference>
                                <el-button link type="danger">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <PaginationBar :totalRecords="totalRecords" :currentPage="currentPage" :pageSize="pageSize"
        @page-change="handlePageChange" @page-size-change="handlePageSizeChange" />
    <el-dialog style="text-align:left" v-model="addVisiable" title="新增" width="500" :before-close="handleClose">
        <el-form label-position='top' ref="addFormRef" :rules="formRules" :model="addForm">
            <el-form-item label='版本' prop="version">
                <el-input v-model="addForm.version"></el-input>
            </el-form-item>
            <el-form-item label='描述' prop="desc">
                <el-input type="textarea" rows="6" v-model="addForm.desc"></el-input>
            </el-form-item>
            <el-form-item label='文件' prop="file">
                <el-upload ref="uploadRef" :action="uploadUrl" :headers="uploadHeader" :on-success="uploadSuccess"
                    :on-remove="handleFileRemove">
                    <el-button type="default"><el-icon class="el-icon--right">
                            <Upload />
                        </el-icon>上传文件</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="add(addFormRef)">
                    添加
                </el-button>
            </div>
        </template>
    </el-dialog>



</template>



<script setup>
import { ref, onMounted, reactive } from 'vue'
import api from './api.js'
import PaginationBar from '@/components/PaginationBar.vue';
import { ElMessage } from "element-plus";

const tableData = ref([]);

const addVisiable = ref(false)
let totalRecords = 0
let currentPage = 1
let pageSize = 10

const addFormRef = ref()
const uploadRef = ref()
const formRules = ref({
    version: [{ required: true, message: '请填写版本号' }],
    desc: [{ required: true, message: '请填写描述' }],
    file: [{ required: true, message: '请上传文件' }]

})

let addForm = reactive({
    version: '',
    file: '',
    file_name: '',
    desc: '',
    status: 0
})

const uploadUrl = process.env.VUE_APP_API_URL + '/api/common/upload'
const uploadHeader = { Authorization: 'Bearer ' + localStorage.getItem('token') }

function showAdd() {
    addVisiable.value = true
}

onMounted(() => {
    getData()
})

function handleClose() {
    resetForm()
    addVisiable.value = false;
}
function itemDelete(row) {
    api.delete(row.id).then(() => {
        getData()
    })
}

function getData() {
    api.index({
        page: currentPage,
        per_page: pageSize
    }).then(res => {
        tableData.value = res.data
        totalRecords = res.meta.total
    })
}

function handlePageChange(current) {
    currentPage = current
    getData()
}
function handlePageSizeChange(size) {
    pageSize = size
    getData()
}

function uploadSuccess(res) {
    addForm.file = res.key
    addForm.file_name = res.fileName
}

async function add() {
    addFormRef.value.validate((valid) => {
        if (valid) {
            api.add(addForm).then(res => {
                if (res?.code !== 0) {
                    ElMessage.error(res?.message | 'add error')
                    return
                }
                addVisiable.value = false
                currentPage = 1
                getData()
                resetForm()
            })
        }
    })




}

function changeStatus(row) {
    api.update(
        row.id,
        { status: row.status ? 0 : 1 }
    ).then(res => {
        if (res?.code !== 0) {
            ElMessage.error(res?.message | '更新失败')
            return
        }
        getData()
    })
}

function resetForm() {
    addFormRef.value?.resetFields()
    uploadRef.value?.clearFiles()

}
function handleFileRemove() {
    addForm.file = ''
    addForm.file_name = ''
}


</script>
